import * as React from 'react'
import PropTypes from 'prop-types'

import { SwiperSlide } from 'swiper/react'

import { GridContainer } from '@/components/Grid'
import Arrows from '@/components/Block/Sliders/atoms/Arrows'
import SocialCard from './SocialCard'

import {
  Container,
  SwiperContent,
  ActionWrapper
} from './style'

const SocialSlider = ({ items }) => {
  return (
    <Container>
      <GridContainer>
        <SwiperContent slidesPerView='auto'>
          {
            items.map((v, k) => (
              <SwiperSlide key={ `social-${k}` }>
                <SocialCard item={ v } />
              </SwiperSlide>
            ))
          }

          <ActionWrapper>
            <Arrows display='inverted' />
          </ActionWrapper>
        </SwiperContent>
      </GridContainer>
    </Container>
  )
}

SocialSlider.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    username: PropTypes.string,
    picture: PropTypes.string,
    image: PropTypes.string
  }))
}

export default SocialSlider
