import * as React from 'react'
import PropTypes from 'prop-types'

import { useTheme } from '@emotion/react'

import resolveAssetURL from '@/utils/resolveAssetURL'

import Icon from '@/components/Icon'

import {
  Container,
  ImageWrapper,
  IconWrapper,
  Image,
  ContentWrapper,
  PictureWrapper,
  Picture,
  InfosWrapper,
  Identity,
  Username
} from './style'

const SocialCard = ({ item }) => {
  const theme = useTheme()

  return (
    <Container>
      <ImageWrapper href={ item.url } target='_blank' title={item.coverAlt}>
        <IconWrapper>
          <Icon
            name='instagram'
            size={ 30 }
            color={ theme.colors.white }
          />
        </IconWrapper>
        <Image loading='lazy' src={ resolveAssetURL(item.coverImg) } alt={item.coverAlt} />
        <span>(open in a new tab)</span>
      </ImageWrapper>
      <ContentWrapper href={ item.postSocialAccountUrl } target='_blank' title="Open our Instagram page in a new tab">
        <PictureWrapper>
          <Picture loading='lazy' src={ resolveAssetURL(item.logo) } alt={ item.logoAlt } />
        </PictureWrapper>
        <InfosWrapper>
          <Identity>
            { item.postUsername }
          </Identity>
          <Username>
            { item.postSocialAccount }
          </Username>
        </InfosWrapper>
        <span>(open in a new tab)</span>
      </ContentWrapper>
    </Container>
  )
}

SocialCard.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    username: PropTypes.string,
    picture: PropTypes.string,
    image: PropTypes.string,
    post: PropTypes.string
  })
}

export default SocialCard
