import styled from '@emotion/styled'

export const ImageWrapper = styled.a`
  width: 100%;
  padding-top: 100%;
  position: relative;

  span {
    position: absolute;
    width: 1px;
    clip: rect(0 0 0 0);
    overflow: hidden;
    white-space: nowrap;
  }
`

export const IconWrapper = styled.div`
  position: absolute;
  top: 2rem;
  left: 2rem;
  z-index: 1;
`

export const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const ContentWrapper = styled.a`
  width: 100%;
  padding: 2.3rem 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  text-decoration: none;

  span {
    position: absolute;
    width: 1px;
    clip: rect(0 0 0 0);
    overflow: hidden;
    white-space: nowrap;
  }
`

export const PictureWrapper = styled.div`
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 50%;
  overflow: hidden;
`

export const Picture = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const InfosWrapper = styled.div`
  padding-left: 1.7rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`

export const Identity = styled.div`
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  font-size: 1.4rem;
  line-height: 1.9rem;
  letter-spacing: .05rem;
  color: ${({ theme }) => theme.colors.green};
`

export const Username = styled.div`
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  font-size: 1.4rem;
  line-height: 1.9rem;
  letter-spacing: .05rem;
  color: ${({ theme }) => theme.colors.green};
`

export const Container = styled.div`
  cursor:pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
  ${IconWrapper}{
    opacity: 0;
    transition: opacity .2s ease;
  }
  &:hover{
    ${IconWrapper}{
      opacity: 1;
      transition: opacity .1s ease;
    }
  }
`
