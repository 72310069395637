import styled from '@emotion/styled'

import { mediaMax, sectionSubtitle, sectionTitle } from '@/styles/mixins'

import WysiwygBase from '@/components/Wysiwyg'

export const Container = styled.div`
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 5rem 0;
  ${mediaMax.xs} {
    margin: 5rem 0;
  }
`

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  padding-top: 5rem;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4.4rem;
  ${mediaMax.xs}{
    margin-bottom: 3.8rem;
  }
`

export const Content = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`
export const Subtitle = styled.span`
  ${sectionSubtitle()}
  color: ${({ theme }) => theme.colors.lightGreen};
  margin-bottom: 2rem;
`

export const Title = styled.span`
  ${sectionTitle()}
  margin-bottom: 1.7rem;
  color: ${({ theme }) => theme.colors.brown};

  span {
    color: ${({ theme }) => theme.colors.green};
  }
`

export const Wysiwyg = styled(WysiwygBase)`
  width: 80%;
  font-size: 1.2rem;
  letter-spacing: 0.1rem;
  color: ${({ theme }) => theme.colors.green};
`
