import styled from '@emotion/styled'
import { Swiper } from 'swiper/react'

import { mediaMax } from '@/styles/mixins'

import 'swiper/css'
import 'swiper/css/virtual'

import {
  LeftArrow,
  RightArrow,
  Wrapper as ArrowWrapper
} from '@/components/Block/Sliders/atoms/Arrows/style'

import { StyledGridContainer } from '@/components/Grid'
export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  ${StyledGridContainer}{
    ${mediaMax.lg}{
      overflow: hidden;
    }
  }
`

export const SwiperContent = styled(Swiper)`
  width: 100%;
  overflow: visible !important;

  .swiper-slide {
    width: 25vw;
    margin-right: 2rem;
    ${mediaMax.xs} {
      width: calc(100% / 1.2);
    }
  }
`

export const ActionWrapper = styled.div`
  width: 100%;

  ${ArrowWrapper} {
    &.inverted {
      justify-content: flex-start;
      flex-direction: row;

      ${LeftArrow},
      ${RightArrow} {
        transform: rotate(0deg);
      }
    }
    ${mediaMax.xs}{
      display: none;
    }
  }
`
