import * as React from 'react'
import PropTypes from 'prop-types'

import { GridContainer } from '@/components/Grid'
import SocialSlider from '@/components/Block/Sliders/SocialSlider'

import {
  Container,
  Wrapper,
  Content,
  Subtitle,
  Title,
  Wysiwyg
} from './style'

const Community = ({ items, title = '', subtitle = '', text = '', action = null }) => {
  return (
    <Container>
      <GridContainer>
        <Wrapper>
          <Content>
            {
              !!subtitle.length && (
                <Subtitle dangerouslySetInnerHTML={ { __html: subtitle } } />
              )
            }
            {
              !!title.length && (
                <Title dangerouslySetInnerHTML={ { __html: title } } />
              )
            }
            {
              !!text.length && (
                <Wysiwyg>{ text }</Wysiwyg>
              )
            }
          </Content>

          { action }
        </Wrapper>
      </GridContainer>
      <SocialSlider items={ items } />
    </Container>
  )
}

Community.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  action: PropTypes.any,
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    username: PropTypes.string,
    picture: PropTypes.string,
    image: PropTypes.string,
    post: PropTypes.string
  }))
}

export default Community
